import { Link } from "gatsby";
import React from "react";
import { bwbHomepageImage, tourDeveloperImage, tourUserImage } from "../images";
import { CallToActionLink } from "./CallToActionLink";
import { CardFlowLayout } from "./CardFlowLayout";
import { ExpandableImage } from "./ExpandableImage";
import { Heading2 } from "./Heading2";
import { PageHeader } from "./PageHeader";
import { Paragraph } from "./Paragraph";
import { Section } from "./Section";

export const GuidedTour: React.FC = () => {
  return (
    <>
      <PageHeader
        primary="A Guided Tour"
        secondary="For your users and developers"
      />
      <Section>
        <Heading2>Guided Tours</Heading2>
        <div className="tos_body_txt">
          <Paragraph>
            <div className="tos_tour_r">
              <ExpandableImage
                src={bwbHomepageImage}
                className="tos_img_spa_diagram"
                thumbnailCaption="All of our tours use the Bob's Wine Bar demo site."
                expandedTitle="All of our tours use the Bob's Wine Bar demo site."
                expandedCaption="The Bob's Wine Bar demo site is a fully functional translation result that includes some sample customization."
              />
            </div>
            <div className="tos_gt_copy">
              We have created several guided tours to introduce you to our
              translation solution and the experience of using it. To make this
              experience as realistic as possible we've created a simple demo
              system on the AS400 and translated it to the cloud using our
              normal process and tools. Our tours will show you before-and-after
              images from the user interface, the generated files including data
              files and code sources, and even include links to a live,
              cloud-hosted version of the translated system. The demo system is
              called "Bob's Wine Bar" and consists of several simple programs
              that query a single data file. Some programs produce on-screen
              results and others produce report output. To view the report
              output we have included a report viewer. We have also included a
              DFU program for viewing and editing of the raw data.
            </div>
            <div className="tos-outer-btn-well" />
            <CallToActionLink
              href="http://demo.twinoaksolutions.com"
              target="bwb_demo"
              className="tos_db_btn"
            >
              Demo Bob's Wine Bar
            </CallToActionLink>
          </Paragraph>
        </div>
        <Paragraph>
          The tour is split into two sections. One section is focused on the
          appearance and usability aspects of the translated system, a primary
          concern of normal system users. The other section focuses on the
          configuration and implementation aspects of translation for developers
          and system support staff.
        </Paragraph>

        <CardFlowLayout>
          <div className="tos_tour_set">
            <Link to="/tour/users">
              <img src={tourUserImage} />
            </Link>
            <CallToActionLink to="/tour/users">
              View the guided tour for Users
            </CallToActionLink>
          </div>
          <div className="tos_tour_set">
            <Link to="/tour/developers">
              <img src={tourDeveloperImage} />
            </Link>
            <CallToActionLink to="/tour/developers">
              View the guided tour for Developers
            </CallToActionLink>
          </div>
        </CardFlowLayout>

        {/* <Paragraph>
          We also have an overview of our build and deployment configuration
          that comes as part of our solution delivery.
        </Paragraph>
        <div className="tos_button_secondary">
          <Link to="/tour/ci-cd">
            <span className="tos_button_text">
              View the Overview of Our CI/CD Pipeline
            </span>
          </Link>
        </div>
        <Paragraph>
          When evaluating your aging platforms and applications, there are many
          reasons you might find to modernize your legacy AS400 RPG system
          including:
        </Paragraph>
        <div className="tos_button_secondary">
          <Link to="/tour/custom-code">
            <span className="tos_button_text">
              View how to Add Custom Code to Your Translated Solution
            </span>
          </Link>
        </div> */}
      </Section>
    </>
  );
};
