import { GatsbyLinkProps, Link } from "gatsby";
import React from "react";

type GatsbyAnchorProps = Omit<GatsbyLinkProps<{}>, "ref">;

type RegularAnchorProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

function isGatsbyLinkProps(
  linkProps: GatsbyAnchorProps | RegularAnchorProps
): linkProps is GatsbyAnchorProps {
  return "to" in linkProps;
}

/**
 * A "call to action" button that begs to be clicked. Renders either a Gatsby link or `<a>` tag, depending on the props
 * given.
 */
export const CallToActionLink: React.FC<
  GatsbyAnchorProps | RegularAnchorProps
> = ({ children, className, ...linkProps }) => {
  const anchorChildren = <span className="tos_button_text">{children}</span>;
  const actualClassName = className
    ? `tos_button_secondary ${className}`
    : "tos_button_secondary";
  return isGatsbyLinkProps(linkProps) ? (
    <Link {...linkProps} className={actualClassName}>
      {anchorChildren}
    </Link>
  ) : (
    <a {...linkProps} className={actualClassName}>
      {anchorChildren}
    </a>
  );
};
