import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties } from "react";
import { user1Image, user2Image, user3Image } from "../images";
import { PageHeader } from "./PageHeader";

const socialButtonBlockStyle: CSSProperties = { textAlign: "center" };
const socialButtonInlineStyle: CSSProperties = { display: "inline-block" };

const SocialButton: React.FC<{ icon: IconProp; href: string }> = ({
  icon,
  href,
}) => (
  <div style={socialButtonBlockStyle}>
    <span style={socialButtonInlineStyle}>
      <a href={href} target="_blank">
        <div className="tos_footer_sm_icon">
          <FontAwesomeIcon icon={icon} />
        </div>
      </a>
    </span>
  </div>
);

export const Team: React.FC = () => {
  return (
    <>
      <PageHeader primary="Team" secondary="Meet the Twin Oak Solutions team" />
      <div className="content_set_team">
        <div className="tos_comp_team">
          <div className="tos_comp_block">
            <div className="tos_comp_team_info">
              <img className="tos_comp_team_info_hs" src={user2Image} />
              <h3 className="tos_comp_h4">Stephen Rossi</h3>
              <p className="tos_comp_user_desc">Product Manager</p>
              <SocialButton
                icon={faLinkedinIn}
                href="https://www.linkedin.com/in/stephen-rossi-68a23621/"
              />
            </div>
            <div className="tos_comp_team_info">
              <img className="tos_comp_team_info_hs" src={user1Image} />
              <h3 className="tos_comp_h4">Don Rich</h3>
              <p className="tos_comp_user_desc">Architect</p>
              <SocialButton
                icon={faLinkedinIn}
                href="https://www.linkedin.com/in/richdon/"
              />
            </div>
            <div className="tos_comp_team_info">
              <img className="tos_comp_team_info_hs" src={user3Image} />
              <h3 className="tos_comp_h4">Jason Bright</h3>
              <p className="tos_comp_user_desc">Principal Engineer</p>
              <SocialButton
                icon={faLinkedinIn}
                href="https://www.linkedin.com/in/jgbright/"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
