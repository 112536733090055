import React from "react";
import { ContactUs } from "../components/ContactUs";
import { Faq } from "../components/Faq";
import { GuidedTour } from "../components/GuidedTour";
import { OurApproach } from "../components/OurApproach";
import { PageLayout } from "../components/PageLayout";
import { Team } from "../components/Team";
import { WhoWeAre } from "../components/WhoWeAre";
import { kitchenSinkPageHeroImage } from "../images";

const KitchenSinkPage: React.FC = () => {
  return (
    <PageLayout heroBackgroundImage={`url(${kitchenSinkPageHeroImage})`}>
      <WhoWeAre />
      <OurApproach />
      <GuidedTour />
      <Team />
      <Faq />
      <ContactUs />
    </PageLayout>
  );
};

export default KitchenSinkPage;
